import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "row g-5 g-xl-8" }
const _hoisted_2 = { class: "row gy-5 g-xl-8" }
const _hoisted_3 = { class: "col-xxl-4" }
const _hoisted_4 = { class: "col-xxl-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BannerButton = _resolveComponent("BannerButton")
  const _component_MixedCardDevices = _resolveComponent("MixedCardDevices")
  const _component_MaintenanceTableDevices = _resolveComponent("MaintenanceTableDevices")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
        return (_openBlock(), _createBlock("div", {
          class: "col-xl-4",
          key: index
        }, [
          _createVNode(_component_BannerButton, {
            "widget-classes": "card-xl-stretch mb-xl-8",
            "svg-icon": item.icon,
            color: item.color,
            "icon-color": "white",
            title: item.title,
            target: item.target,
            description: item.description
          }, null, 8, ["svg-icon", "color", "title", "target", "description"])
        ]))
      }), 128))
    ]),
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode(_component_MixedCardDevices, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          "widget-color": "primary",
          "chart-height": 200,
          "stroke-color": "#cb1e46"
        })
      ]),
      _createVNode("div", _hoisted_4, [
        _createVNode(_component_MaintenanceTableDevices, {
          "widget-classes": "card-xxl-stretch mb-5 mb-xl-8",
          BtnVisible: false
        })
      ])
    ])
  ], 64))
}