
import { defineComponent, ref } from "vue";
import { Field } from "vee-validate";
import Dropdown3 from "@/components/dropdown/Dropdown3.vue";
import { useI18n } from "vue-i18n";
import ApiService from "@/core/services/ApiService";
export default defineComponent({
  name: "kt-account",

  components: {
    Dropdown3,
    Field
  },
  props:{
    id:String,
    values: String,
    mac: String,
  },
  methods: {
    redirectPDF() {
      window.open('https://api.sistena.io/static/docs/' + this.device['device'] + '.pdf', '_blank');
    }
  },
  setup(props) {
    const device = ref({ _id: "",
      MAC: "",
      wifiModule: "",
      device: "",
      status: "",
      dateOfRegister: 0,
      update: "",
      registers: {},
      associatedTo: "",
      fwName: "",
      version: "",
      vendorInfo:{
        _id:" ",
        name: " "
      }
      
    });
    const valueNewVendor = ref({
      vendorId: "",
      deviceId: ""
    });
    const selected = ref('');
    const vendors = ref ({ })
    const { t, te } = useI18n();
    const translate = text => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };
    const fetchDevice = async () => {
      // TODO: refactorizar esto se puede hacer mas bonito
      if ((localStorage.device !== undefined) && (localStorage.device !== null) && (localStorage.device !== "undefined")) {
        device.value = JSON.parse(localStorage.getItem("device") || "{}");
        if (device.value.MAC !== props.mac)
        {
          await ApiService.setHeader();
          const datos = await ApiService.get("devices", props.id?props.id:device.value._id);
          device.value = datos.data.device;
         
          localStorage.setItem('device', JSON.stringify(datos.data.device))  
          
        }
      } else {
        await ApiService.setHeader();
        const datos = await ApiService.get("devices", props.id?props.id:device.value._id);
        device.value = datos.data.device;
        localStorage.setItem('device', JSON.stringify(datos.data.device))
        
      }
      
      
      return device
    };
    const fetchVendors = async () => {
      await ApiService.setHeader();
      
      const datos = await ApiService.get("vendors", props.id?props.id:device.value._id);
      vendors.value = datos.data.vendors;
      
      
      return vendors
      
    }
    const onChange = async(event)=>{
      const vendorChange = event.target.value;
      const deviceId = device.value._id;
      const datosVendorDevice = {
        vendorChange: event.target.value,
        deviceId: device.value._id
      }
      await ApiService.setHeader();
      const datos = await ApiService.post("vendorChange", datosVendorDevice);
      console.log(datos)
      await ApiService.setHeader();
      const datos2 = await ApiService.get("devices", props.id?props.id:device.value._id);
      device.value = datos2.data.device;
      localStorage.setItem('device', JSON.stringify(datos2.data.device))
      
    }

    return {
      translate,
      fetchDevice,
      
      fetchVendors,
      onChange,
      vendors,
      device,
      selected
    };
  },
  async beforeMount() {
    //const dev = this.$route.params.id;
    //await ApiService.setHeader();
    //const data = await ApiService.get("device", "1245");
    //console.log(data);
  },
  created() {
    this.fetchDevice();
    this.fetchVendors();
    console.log(this.device)
    if ("vendorInfo" in this.device)
      this.selected = this.device["vendorInfo"]._id;
  },
  mounted() {
    console.log(this.device)
    console.log(this.$route.params.id);
  }
});
