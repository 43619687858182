
import { defineComponent, onMounted, ref } from "vue";
import DeviceRecords from "@/components/widgets/sistena/DeviceRecordsTable.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
export default defineComponent({
  name: "account-overview",
  components: {
    DeviceRecords
  },
  props: {
    records: {},
    id: String,
    mac: String,
    model: String
  },
  setup(props) {
    interface RegType {
      description: string;
      metric: boolean;
      tipo: string;
    }
    const regdescript = ref<string[]>([]);
    const regmetrics = ref<boolean[]>([]);
    const device = ref({
      _id: "",
      MAC: "",
      wifiModule: "",
      device: "",
      status: "",
      dateOfRegister: 0,
      update: "",
      registers: {},
      associatedTo: "",
      fwName: "",
      version: ""
    });

    onMounted(async () => {
      device.value = JSON.parse(localStorage.getItem("device") || "{}");
      console.log(device.value);
      setCurrentPageBreadcrumbs("Overview", ["Account"]);
      const registros = await ApiService.get("models", device.value.device);
      registros.data.registersDescription.forEach(item => {
        const datos: RegType = {
          description: "",
          metric: false,
          tipo: ""
        };
        if (item.metric) datos.metric = true;
        else datos.metric = false;
        datos.description = item.description.split("[")[0];
        datos.tipo = item.description.split("[")[1].split("]")[0];
        if (item.metric) regmetrics.value.push(true);
        else regmetrics.value.push(false);
        regdescript.value.push(item.description);
      });
    });
    return {
      regdescript,
      regmetrics,
      device
    };
  }
});
