export default {
    template: '<img style="width: 20px;" :src="imgForMood" :title="imgToolTip"/>',
    data() {
      return {
        mood: 'Happy',
        imgForMood: null,
        imgToolTip: "",
      };
    },
    methods: {
      refresh(params) {
        this.params = params.data;
        this.setMood(this.params);
      },
  
      setMood(params) {
        if ((params.dBm > -120) && (params.dBm <= -104)){
          this.imgForMood = 'media/icons/signal/baja.png'
          this.imgToolTip = params.dBm
        }
        if ((params.dBm > -104) && (params.dBm <= -98)){
          this.imgForMood = 'media/icons/signal/baja2.png'
          this.imgToolTip = params.dBm
        }
        if ((params.dBm > -97) && (params.dBm <= -90)){
          this.imgForMood = 'media/icons/signal/media.png'
          this.imgToolTip = params.dBm
        }
        if ((params.dBm > -90) && (params.dBm <= -77)){
          this.imgForMood = 'media/icons/signal/media2.png'
          this.imgToolTip = params.dBm
        }
        if (params.dBm > -77){
          this.imgForMood = 'media/icons/signal/alta.png'
          this.imgToolTip = params.dBm
        }
      },
    },
    created() {
      console.log('En el componente:', this.params.data)
      this.setMood(this.params.data);
    },
  };