<template>
  <div>
    <div class="accordion" id="accordionLinkList">
      <div class="accordion-item" v-for="(link, index) in linkList" :key="index">
        <h2 class="accordion-header" :id="'headingLink' + index">
          <button
            class="accordion-button"
            type="button"
            :data-bs-toggle="'collapse'"
            :data-bs-target="'#collapseLink' + index"
            :aria-expanded="'false'"
            :aria-controls="'collapseLink' + index"
          >
            {{ link.text }}
          </button>
        </h2>
        <div
          :id="'collapseLink' + index"
          class="accordion-collapse collapse"
          :aria-labelledby="'headingLink' + index"
          :data-bs-parent="'#accordionLinkList'"
        >
          <div class="accordion-body">
            <!-- Contenido específico para cada enlace (opcional) -->
            <p>{{ link.descripcion }}</p>

            <!-- Ejemplo de enlace dentro del contenido -->
            <a :href="link.url" target="_blank">{{ link.text }} Link</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      linkList: [
        { text: 'Guia de buenas prácticas conectividad wifi Sistena', descripcion: 'Para asegurar la estabilidad e idoneidad del funcionamiento del sistema, es necesario confirmar las características reales de la instalación en materia de comunicaciones generales ', url: '/media/pdfs/Doc1.pdf' },
        { text: 'Vinculación ONIX Sistena', descripcion: 'PROCESO DE VINCULACIÓN DE USUARIO Y DISPOSITIVOS A APP ONIX SISTENA  ', url: '/media/pdfs/Doc2.pdf' },
        
        // Agrega más enlaces según necesites
      ],
    };
  },
};
</script>

<style scoped>
/* Estilos personalizados para el acordeón (opcional) */
.accordion-button {
  background-color: #f5f5f5;
  color: #333;
  font-weight: bold;
}
</style>