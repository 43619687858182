
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import RegistersTable from "@/components/widgets/sistena/RegistersTable.vue";
import ApiService from "@/core/services/ApiService";
import * as Yup from "yup";
interface RegistersDescription {
  description: string;
  bytes: {
    upperByte: string;
    lowerByte: string;
  };
  metric: boolean;
}
interface SchemaModel {
  _id: string;
  model: string;
  registersDescription: RegistersDescription[];
}

export default defineComponent({
  name: "model",
  props: {
    model: String,
    data: String
  },
  components: {
    ErrorMessage,
    Field,
    RegistersTable,
    Form
  },
  setup(props) {
    const submitButton1 = ref<HTMLElement | null>(null);
    const schemaModel = ref<SchemaModel>({
      _id: "",
      model: "",
      registersDescription: []
    });
    const profileDetailsValidator = Yup.object().shape({
      model: Yup.string()
        .required()
        .label("Modelo")
    });
    const saveChanges1 = () => {
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");
        }, 2000);
      }
    };
    onMounted(async () => {
      schemaModel.value = JSON.parse(props.data || "{}");
      console.log(schemaModel.value);
      setCurrentPageBreadcrumbs("Overview", ["Account"]);
    });
    return {
      schemaModel,
      submitButton1,
      saveChanges1,
      profileDetailsValidator
    };
  }
});
