const DocMenuConfig: object = [
  {
    pages: [
      {
        heading: "dashboard",
        admin:false,
        route: "/dashboard",
        svgIcon: "media/icons/duotone/Home/Home-heart.svg",
        fontIcon: "bi-app-indicator"
      },
      {
        heading: "account",
        admin:false,
        route: "/account",
        svgIcon: "media/icons/duotone/General/User.svg",
        fontIcon: "bi-person"
      },
      {
        heading: "services",
        admin:false,
        route: "/services",
        svgIcon: "media/icons/duotone/Layout/Layout-4-blocks.svg",
        fontIcon: "bi-layer"
      },
      {
        heading: "admin",
        admin:true,
        route: "/admin",
        svgIcon: "media/icons/duotone/Layout/Layout-4-blocks.svg",
        fontIcon: "bi-layer"
      }
    ]
  }
];

export default DocMenuConfig;
