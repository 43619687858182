import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card-header border-0 pt-5" }
const _hoisted_2 = { class: "card-title align-items-start flex-column" }
const _hoisted_3 = { class: "card-label fw-bolder fs-3 mb-1" }
const _hoisted_4 = { class: "text-muted fw-bold fs-7" }
const _hoisted_5 = { class: "card-toolbar" }
const _hoisted_6 = {
  type: "button",
  class: "btn btn-sm btn-icon btn-color-primary btn-active-light-primary",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "top-end"
}
const _hoisted_7 = { class: "svg-icon svg-icon-2" }
const _hoisted_8 = { class: "card-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")
  const _component_Dropdown2 = _resolveComponent("Dropdown2")
  const _component_apexchart = _resolveComponent("apexchart")

  return (_openBlock(), _createBlock("div", {
    class: ["card", _ctx.widgetClasses]
  }, [
    _createVNode("div", _hoisted_1, [
      _createVNode("h3", _hoisted_2, [
        _createVNode("span", _hoisted_3, _toDisplayString(_ctx.descrec), 1),
        _createVNode("span", _hoisted_4, _toDisplayString(_ctx.typerec), 1)
      ]),
      _createVNode("div", _hoisted_5, [
        _createVNode("button", _hoisted_6, [
          _createVNode("span", _hoisted_7, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotone/Layout/Layout-4-blocks-2.svg" })
          ])
        ]),
        _createVNode(_component_Dropdown2)
      ])
    ]),
    _createVNode("div", _hoisted_8, [
      (_ctx.series[0].data)
        ? (_openBlock(), _createBlock(_component_apexchart, {
            key: 0,
            type: "line",
            options: _ctx.options,
            series: _ctx.series
          }, null, 8, ["options", "series"]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}