
import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";

export default defineComponent({
  name: "dropdown-2",
  components: {},
  setup(props) {
    const store = useStore();
    const refresh = async (tiempo,inter) => {
      const params = {
          deviceId:  localStorage.getItem("staticsID"),
          register: localStorage.getItem("staticsREG"),
          backTime: tiempo,
          period: inter
        };
      
      await ApiService.setHeader()
      store.dispatch(Actions.SET_STATISTICS, params).then(() => {
        console.log("terminado");
      });
    }
    return {
      refresh
    }
  }
});
