
import { defineComponent, onMounted } from "vue";
import DevicesTable from "@/components/widgets/sistena/DevicesTable.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
// import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "account-overview",
  components: {
    DevicesTable
  },
  setup() {
    //const list = ref([]);
    // const fetchDevices = async () => {
      // await ApiService.setHeader();
      // const datos = await ApiService.get("devices");
      // datos.data.devices.map( item => {
      //   'status' in item ?  null : item.status = 'pending'
      //   item.clientInfo !== undefined ? item.client = item.clientInfo.name : null
      //   if (!isNaN(Number(item.dateOfRegister))) {
      //     const date = new Date(Number(item.dateOfRegister))
      //     date instanceof Date && date.getFullYear() !== 1970 ? item.date = date.toLocaleString('es-ES', { day: "2-digit", year:"numeric", month:"2-digit"}) : null
      //   }
      // })
      // list.value = datos.data.devices;
      // console.log(list.value);
    //};
    onMounted(() => {
      setCurrentPageBreadcrumbs("Overview", ["Account"]);
    });
    return {
      // list,
      // fetchDevices
    };
  },
  created() {
    // this.fetchDevices();
  }
});
