
import { defineComponent, onMounted, ref } from "vue";
import UsersTable from "@/components/widgets/sistena/UsersTable.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "account-overview",
  props:{
    model: String
  },
  components: {
    UsersTable
  },
  setup() {
    const list = ref([]);
    const fetchModels = async () => {
      await ApiService.setHeader();
      const models = await ApiService.get("clients");
      list.value = models.data;
      console.log(list.value);
    };
    onMounted(() => {
      setCurrentPageBreadcrumbs("Overview", ["Account"]);
    });
    return {
      list,
      fetchModels
    };
  },
  created() {
    this.fetchModels();
  }
});
