<template>
<ag-grid-vue
  style="width: 100%; height: 60%;"
  class="ag-theme-alpine"
  :columnDefs="columnDefs"
  @grid-ready="onGridReady"
  :defaultColDef="defaultColDef"
  :defaultColGroupDef="defaultColGroupDef"
  :columnTypes="columnTypes"
  :rowData="rowData"
  :sideBar="sideBar">
</ag-grid-vue>
</template>

<script>

import moment from 'moment'

import ApiService from "@/core/services/ApiService";
// ag-grid
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-community'
import 'ag-grid-enterprise'

import Swal from 'sweetalert2'

import { AgGridVue } from 'ag-grid-vue3';
import MoodRenderer from './moodRendererVue.js';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { ModuleRegistry } from '@ag-grid-community/core';
// Register the required feature modules with the Grid
ModuleRegistry.registerModules([ClientSideRowModelModule]);

import { defineComponent } from "vue";
import { useRouter } from 'vue-router';

export default defineComponent({
  name: "devices-table",
  components: {
    'ag-grid-vue': AgGridVue,

     /* eslint-disable vue/no-unused-components */
    moodCellRenderer: MoodRenderer
  },
  props: {
    list: null
  },
  setup() {
    const router = useRouter()
    return {
      columnDefs: [
        {
          headerName: 'MAC',
          field: 'MAC',
          initialPinned: 'left',
          filterParams: {
            excelMode: 'mac',
          },
        },
        { headerName: 'Modelo', field: 'device' },
        { headerName: 'Email disp', field: 'email', initialHide:'true' },
        { headerName: 'Cliente', field: 'clientInfo.name' },
        { headerName: 'Email cliente', field: 'clientInfo.email', initialHide:'true' },
        { headerName: 'Usuario', field: 'user', initialHide:'true' },
        { headerName: 'Email usuario', field: 'userInfo.email', initialHide:'true' },
        { headerName: 'Nombre', field: 'userInfo.givenName', initialHide:'true' }, 
        { headerName: 'Distribuidor', field: 'vendorInfo.name', initialHide: 'false'},
        { headerName: 'Fecha registro', field: 'date' },
        { headerName: 'Estado', field: 'status' },
        
       {
          headerName: "dBm",
          field: "dBm",
          cellRendererSelector: (params) => {
              const moodDetails = { component: 'moodCellRenderer' };
              if (params.data.dBm)
                return moodDetails;
              else 
                return null
          }
        },
        { headerName: 'IP', field: 'ipPublic' },
        { 
          field: '',
          cellRenderer: (params) => {
            const route = {
              name: "device",
              params: {
                id: params.data._id,
                mac: params.data.MAC
              }
            };
            const link = document.createElement("a");
            link.href = router.resolve(route).href;
            link.innerText = 'view';
            link.addEventListener("click", e => {
              e.preventDefault();
              router.push(route);
            });
            return link;
          }, resizable: false, filter: false, sortable: false, suppressMovable: true, enableValue: false, enableRowGroup: false, enablePivot: false, initialPinned: 'right'
        },{ 
          field: '',
          cellRenderer: (params) => {
            const link = document.createElement("img");
            link.src = 'media/icons/reset.png';
            link.addEventListener("click", e => {
              e.preventDefault();
              Swal.fire({
                icon: 'warning',
                title: 'ATENCION',
                text: 'Esto restaurá la configuración de fábrica en el dispositivo.',
                showCancelButton: true,
                confirmButtonText: 'Reset',
              }).then( (result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                  ApiService.post("reset", { mac: params.data.MAC.toString()}).then((result) => {
                    if (result.status === 200) {
                      console.log(result);                     
                      Swal.fire('Reset!', `Se ha mandado el reset al dispostivo con la mac: ${ result.data }.`) 
                    }
                  })
                }
              })
            });
            return link;
          }, resizable: false, filter: false, sortable: false, suppressMovable: true, enableValue: false, enableRowGroup: false, enablePivot: false, initialPinned: 'right'
        },
      ],
      gridApi: null,
      gridColumnApi: null,
      defaultColDef: {
        width: 150,
        filter: true,
        sortable: true,
        resizable: true
      },
      defaultColGroupDef: {},
      columnTypes: {},
      rowData: null,
      sideBar: null,
    };
  },
  created() {
    this.defaultColGroupDef = {
      marryChildren: true,
    };
    this.columnTypes = {
      numberColumn: { width: 83 },
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressColumnFilter: true,
            suppressColumnSelectAll: true,
            suppressColumnExpandAll: true,
          },
        },
      ],
      defaultToolPanel: 'columns',
    };
  },
  beforeCreate() {
    // console.log(this.list);
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;

    
      // params.api.sizeColumnsToFit();
      // this.autoSizeAll()

      const updateData = (data) => params.api.setRowData(data);
      
      ApiService.get("devices")
        .then((result) => {
          result.data.devices.map((i) => {
              i.date = moment(i.dateOfRegister).format('DD/MM/YYYY')
          })
          updateData(result.data.devices)
          this.autoSizeAll()
          this.gridApi.closeToolPanel()
        })
    },
    autoSizeAll() {
      const allColumnIds = [];
      this.gridColumnApi.getAllColumns().forEach(function(column) {
        allColumnIds.push(column.colId);
      });
      this.gridColumnApi.autoSizeColumns(allColumnIds);
    }
  }
});
</script>
