
import { defineComponent } from "vue";
import Dropdown2 from "@/components/dropdown/Dropdown2.vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "devices-table",
  components: {
    Dropdown2
  },
  props: {
    widgetClasses: String,
    list: []
  },
  setup() {
    const { t, te } = useI18n();
    const translate = text => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };
    return {
      translate
    };
  },
  beforeCreate() {
    console.log(this.list);
  }

});
