
import { defineComponent, onBeforeMount, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useI18n } from "vue-i18n";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import WidgetChart1 from "@/components/widgets/sistena/WidgetChart1.vue";
import ApiService from "@/core/services/ApiService";
export default defineComponent({
  name: "device-statics",
  components: {
    WidgetChart1
  },
  props: {
    records: {},
    id: String,
    mac: String,
    model: String,
    reg: Number
  },
  setup(props) {
    const store = useStore();
    const device = ref({});
    const labels = ref<string[]>([]);
    const values = ref<number[]>([]);

    const { t, te } = useI18n();

    const translate = text => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };
    const fetchDevice = async () => {
      let params = {};
      if (props.id !== undefined && props.reg !== undefined) {
        localStorage.setItem("staticsID", props.id);
        localStorage.setItem("staticsREG", props.reg.toString());
        params = {
          deviceId: props.id,
          register: props.reg,
          period: 5,
          backTime: 1
        };
      } else {
        params = {
          deviceId: localStorage.getItem("staticsID"),
          register: localStorage.getItem("staticsREG"),
          period: 5,
          backTime: 1
        };
      }
      await ApiService.setHeader()
      store.dispatch(Actions.SET_STATISTICS, params).then(() => {
        console.log("terminado");
        values.value = store.getters.getStatistics;
        labels.value = store.getters.getLabels;
      });
      // await ApiService.setHeader();
      // const datos = await ApiService.getObj("metrics", { params });
      // device.value = datos.data.values;
      // datos.data.values.map(item => {
      //   const localDate = new Date(item.timestamp);
      //   labels.value.push(localDate.toLocaleTimeString());

      //   if (item.value == "") values.value.push(0);
      //   else {
      //     let valor = parseInt(item.value);
      //     valor = Math.round((valor + Number.EPSILON) * 10) / 100;
      //     values.value.push(valor);
      //   }
      // });
      // return device;
    };
    onBeforeMount(async () => {
      setCurrentPageBreadcrumbs("Overview", ["Account"]);
      fetchDevice();
    });

    return {
      translate,
      fetchDevice,
      device,
      values,
      labels
    };
  }
});
