import { createApp } from "vue/dist/vue.esm-bundler";
import App from "./App.vue";
import firebase from "firebase/app";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";

//imports for app initialization
// import MockAdapter from "@/core/mock/MockService";
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";

import "@/core/plugins/keenthemes";
import "@/core/plugins/prismjs";
import "bootstrap";

const firebaseConfig = {
  apiKey: "AIzaSyBTyIySTVF3rj0H5i0Fahx9MAcs_MeQ1HY",
  authDomain: "sistenaiot.firebaseapp.com",
  databaseURL: "https://sistenaiot-default-rtdb.firebaseio.com",
  storageBucket: "sistenaiot.appspot.com",
  messagingSenderId: "313983540523"
};
firebase.initializeApp(firebaseConfig);
const app = createApp(App);

app.use(store);
app.use(router);
app.use(ElementPlus);

ApiService.init(app);
// MockAdapter.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);

app.mount("#app");
