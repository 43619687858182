
import { defineComponent, ref } from "vue";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "mixed-card-devices",
  props: {
    widgetClasses: String,
    widgetColor: String,
    chartHeight: Number
  },
  data() {
    return {
      list: [{}],
      total: Number
    };
  },
  mounted() {
    const lista: any[] = [];
    const fetchDevices = async () => {
      await ApiService.setHeader();
      const datos = await ApiService.get("/devices/totals");
      this.total = datos.data.total;
      const keys = Object.keys(datos.data);
      keys.splice(keys.indexOf('total'), 1);
      for (let i = 0; i < keys.length; i++) {
        const newObj = {
          image: `media/stock/600x400/${ keys[i] }.png`,
          title: keys[i],
          size: datos.data[keys[i]]
        }
        lista.push(newObj);
      }
      console.log(lista);
      this.list = lista;
    }
    fetchDevices()
  }
});
