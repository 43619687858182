
import { defineComponent, ref, onMounted, watch, onBeforeMount } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import Dropdown2 from "@/components/dropdown/DropdownStatics.vue";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";



export default defineComponent({
  name: "widget-1",
  props: {
    widgetClasses: String,
    descrec: String,
    typerec: String
  },
  components: {
    Dropdown2
  },
  setup(props) {
    const store = useStore();
    const labelColor = getCSSVariableValue("--bs-gray-500");
    const borderColor = getCSSVariableValue("--bs-gray-200");
    const baseColor = getCSSVariableValue("--bs-primary");
    const secondaryColor = getCSSVariableValue("--bs-gray-300");
    const labels = ref<string[]>([]);
    const values = ref<number[]>([]);
    const options = ref({
      chart: {
        fontFamily: "inherit",
        type: "line",
        toolbar: {
          show: true
        }
      },

      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: ["30%"],
          endingShape: "rounded"
        }
      },
      legend: {
        show: true
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,

      },
      xaxis: {
        categories: labels.value
      },
      yaxis: {
        labels: {
          style: {
            colors: labelColor,
            fontSize: "12px"
          }
        }
      },
      fill: {
        opacity: 1
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0
          }
        },
        hover: {
          filter: {
            type: "none",
            value: 0
          }
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0
          }
        }
      },
      tooltip: {
        style: {
          fontSize: "11px"
        }
      },
      colors: [baseColor, secondaryColor],
      grid: {
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true
          }
        }
      }
    });
    store.subscribe((mutation, state) => {
      const pieces = mutation.type

      if (pieces == "setStatisticsMutation") {
        values.value.length = 0
        store.getters.getStatistics.map((i) =>{
        values.value.push(i)
      })
      labels.value.length = 0
        store.getters.getLabels.map((i) =>{
        labels.value.push(i)
      })
      
       options.value = {...options.value, ...{
            xaxis: {
                categories: labels.value
            }
          }
      }
      }
     
      console.log("cambio")
    })
    const series = ref([
      {
        name: "Lecturas",
        data: values.value
      }
    ]);
    
    onMounted(async () => {
      MenuComponent.reinitialization();
      
    });
   
    
    return {
      options,
      series
    };
    
  }
  
});
