
import { defineComponent, onMounted, ref } from "vue";
import ClientsTable from "@/components/widgets/sistena/ClientsTable.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "account-overview",
  props:{
    model: String
  },
  
  setup() {
    const list = ref([]);
    let deviceMac
    let distributorName
    let selectedClients
    let selectedClient
    const fetchModels = async () => {
      await ApiService.setHeader();
      const models = await ApiService.get("clients");
      list.value = models.data.clients;
      console.log(list.value);
      
    };
    onMounted(() => {
      setCurrentPageBreadcrumbs("Overview", ["Account"]);
    });
    return {
      list,
      deviceMac,
      distributorName,
      selectedClients,
      selectedClient,
      fetchModels
    };
  },
  created() {
    this.fetchModels();
  },
  methods: {
    saveChangesDevice() {
      // Handle form submission
      // You can access the form data in this.distributorName and this.selectedClients
      console.log('Distributor Name:', this.deviceMac);
      console.log('Selected Clients:', this.selectedClient);
    },
    saveChangesDistributor() {
      // Handle form submission
      // You can access the form data in this.distributorName and this.selectedClients
      console.log('Distributor Name:', this.distributorName);
      console.log('Selected Clients:', this.selectedClients);
    },
  },
});
