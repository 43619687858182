
import { defineComponent, onMounted, ref } from "vue";
import { config } from "@/core/helpers/config";
import { themeName } from "@/core/helpers/documentation";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ServicesTable from "@/components/widgets/sistena/ServicesTable.vue";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "builder",
  components: {
    ServicesTable,
  },
  setup() {
    const tabIndex = ref(0);
    const list = ref([]);
    const fetchServices = async () => {
      await ApiService.setHeader();
      const response = await ApiService.get("services");
      response.data.services.map( item => {
        item.status2 = item.status
        item.status2.split(":")[0] ===  "connected" ? item.status = "online" : item.status = 'offline'
        item.timestamp = item.status2.split(":")[1].trim()
        const date = new Date(Number(item.timestamp))
        item.datetime = date.toLocaleString('es-ES')
        const diff =  Date.now() - Number(item.timestamp)
        const interval = item.interval || 60000
        item.status = diff > interval ? "offline" : item.status
      })
      list.value = response.data.services;
      console.log(list.value);
    };

    onMounted( () => {
      // set the tab from previous
      tabIndex.value = parseInt(localStorage.getItem("builderTab") || "0");
      setCurrentPageTitle("Layout Builder");
    });

    /**
     * Reset config
     * @param event
     */
    const reset = event => {
      event.preventDefault();
      // remove existing saved config
      localStorage.removeItem("config");
      window.location.reload();
    };

    /**
     * Set active tab when the tab get clicked
     * @param event
     */
    const setActiveTab = event => {
      tabIndex.value = parseInt(event.target.getAttribute("data-tab-index"));
      // keep active tab
      localStorage.setItem("builderTab", tabIndex.value.toString());
    };

    /**
     * Submit form
     * @param event
     */
    const submit = event => {
      event.preventDefault();
      // save new config to localStorage
      localStorage.setItem("config", JSON.stringify(config.value));
      window.location.reload();
    };

    return {
      tabIndex,
      config,
      reset,
      setActiveTab,
      submit,
      themeName,
      list,
      fetchServices
    };
  },
  created() {
    this.fetchServices();
  }
});
