import { createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-items-stretch flex-shrink-0" }
const _hoisted_2 = {
  class: "d-flex align-items-center ms-1 ms-lg-3",
  id: "kt_header_user_menu_toggle"
}
const _hoisted_3 = {
  class: "cursor-pointer symbol symbol-30px symbol-md-40px",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-attach": "parent",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "bottom"
}
const _hoisted_4 = {
  class: "d-flex align-items-center d-lg-none ms-2 me-n3",
  title: "Show header menu"
}
const _hoisted_5 = {
  class: "btn btn-icon btn-active-light-primary",
  id: "kt_header_menu_mobile_toggle"
}
const _hoisted_6 = { class: "svg-icon svg-icon-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTUserMenu = _resolveComponent("KTUserMenu")
  const _component_inline_svg = _resolveComponent("inline-svg")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode("img", {
          src: `media/avatars/${_ctx.user.uid}.jpg`,
          alt: "metronic"
        }, null, 8, ["src"])
      ]),
      _createVNode(_component_KTUserMenu)
    ]),
    _createVNode("div", _hoisted_4, [
      _createVNode("div", _hoisted_5, [
        _createVNode("span", _hoisted_6, [
          _createVNode(_component_inline_svg, { src: "media/icons/duotone/Text/Toggle-Right.svg" })
        ])
      ])
    ])
  ]))
}