import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import firebaseAuth from "firebase";

export interface User {
  name: string;
  surname: string;
  email: string;
  password: string;
  token: string;
}

export interface UserAuthInfo {
  errors: Array<string>;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = [];
  user = {} as User;
  isAuthenticated = !!JwtService.getToken();

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors(): Array<string> {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    this.user = user;
    this.errors = [];
    JwtService.saveToken(user.Aa);
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    JwtService.destroyToken();
  }

  @Action
  async [Actions.LOGIN](credentials) {
    try {
      const found = await firebaseAuth
        .auth()
        .signInWithEmailAndPassword(
          credentials["email"],
          credentials["password"]
        );
      if (found) {
        return [200, found];
      }
      return [404, { errors: ["The login detail is incorrect"] }];
      // const data = await ApiService.post("login", credentials);
      // this.context.commit(Mutations.SET_AUTH, data.data);
    } catch (error) {
      this.context.commit(Mutations.SET_ERROR, error);
      throw new Error("");
    }
  }
  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.REGISTER](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("registration", credentials)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  async [Actions.FORGOT_PASSWORD](payload) {
    try {
      const found = await firebaseAuth
        .auth()
        .sendPasswordResetEmail(payload.email)
        .then(() => {
          return [200, found];
        });
      return [404, { errors: ["The login detail is incorrect"] }];
      // const data = await ApiService.post("login", credentials);
      // this.context.commit(Mutations.SET_AUTH, data.data);
    } catch (error) {
      this.context.commit(Mutations.SET_ERROR, error);
      throw new Error("");
    }
  }

  @Action
  async [Actions.VERIFY_AUTH]() {
    await firebaseAuth.auth().onAuthStateChanged(user => {
      if (user) {
        this.context.commit(Mutations.SET_AUTH, user);
      } else {
        this.context.commit(Mutations.PURGE_AUTH);
      }
    });

    //if (!usuario.currentUser && router.currentRoute.value['path'] !== "/sign-in") {
    //  console.log(router.currentRoute.value['path']);
    //}
    // if (JwtService.getToken()) {
    //   ApiService.setHeader();
    //   ApiService.get("verify")
    //     .then(({ data }) => {
    //       this.context.commit(Mutations.SET_AUTH, data);
    //     })
    //     .catch(({ response }) => {
    //       this.context.commit(Mutations.SET_ERROR, response.data.errors);
    //     });
    // } else {
    //   this.context.commit(Mutations.PURGE_AUTH);
    // }
  }

  @Action
  [Actions.UPDATE_USER](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("update_user", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USER, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }
}
