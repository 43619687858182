import { createI18n } from "vue-i18n";

const messages = {
  en: {
    account: "Account",
    admin: "Administration",
    accountOverview: "Overview",
    accountsettings: "Account Settings",
    apps: "Apps",
    authentication: "Authentication",
    basicFlow: "Basic Flow",
    campaigns: "Campaigns",
    changelog: "Changelog",
    chat: "Chat",
    connections: "Connections",
    craft: "Crafted",
    createAccount: "Create Account",
    createAPIKey: "Create API Key",
    createApp: "Create App",
    dashboard: "Home",
    date: "Date",
    devices: "Devices",
    docsAndComponents: "Docs & Components",
    documents: "Documents",
    drawerChat: "Drawer Chat",
    error404: "Error 404",
    error500: "Error 500",
    exampleLink: "Example link",
    forms: "Forms",
    general: "General",
    groupChat: "Group Chat",
    horizontal: "Horizontal",
    inviteFriends: "Invite Friends",
    language: "Language",
    layoutBuilder: "Layout builder",
    megaMenu: "Mega Menu",
    modals: "Modals",
    model: "Model",
    models: "Models",
    client: "Client",
    clients: "Clients",
    modwifi: "Wifi Module",
    downldocumentation: "Documentation",
    metrics: "Metrics",
    myproducts: "My Products",
    myprofile: "My Profile",
    newAddress: "New Address",
    newCard: "New Card",
    newTarget: "New Target",
    pages: "Pages",
    passwordReset: "Password Reset",
    privateChat: "Private Chat",
    profile: "Profile",
    profileOverview: "Overview",
    projects: "Projects",
    records: "Records",
    services: "Services",
    settings: "Settings",
    shareAndEarn: "Share & Earn",
    signIn: "Sign-in",
    signout: "Sign-out",
    signUp: "Sign-up",
    softwarev: "Software Version",
    statistics: "Statistics",
    status: "Status",
    twoFactorAuth: "Two Factor Auth",
    upgradePlan: "Upgrade Plan",
    user: "User",
    users: "Users",
    value: "Value",
    vertical: "Vertical",
    view: "View",
    viewUsers: "View Users",
    widgets: "Widgets",
    widgetsCharts: "Charts",
    widgetsFeeds: "Feeds",
    widgetsLists: "Lists",
    widgetsMixed: "Mixed",
    widgetsStatistics: "Statistics",
    widgetsTables: "Tables",
    wizards: "Wizards",
    name: "Name",
    timestamp: "Time Stamp",
    vendor:"Vendor",
  },
  es: {
    account: "Cuenta",
    admin: "Administración",
    accountOverview: "Descripción general",
    accountsettings: "Configuración Cuenta",
    apps: "Aplicaciones",
    authentication: "Autenticación",
    basicFlow: "Flujo básico",
    campaigns: "Campañas",
    changelog: "Registro de cambios",
    chat: "Chat",
    connections: "Conexiones",
    craft: "Elaborado",
    createAccount: "Crear Una Cuenta",
    createAPIKey: "Crea Clave De Api",
    createApp: "Crear Aplicacion",
    dashboard: "Home",
    date: "Fecha",
    devices: "Dispositivos",
    docsAndComponents: "Documentos & Componentes",
    documents: "Documentos",
    drawerChat: "Chat del cajón",
    error404: "Error 404",
    error500: "Error 500",
    exampleLink: "Enlace de ejemplo",
    forms: "Formas",
    general: "General",
    groupChat: "Grupo de chat",
    horizontal: "Horizontal",
    inviteFriends: "Invitar A Amigos",
    language: "Idioma",
    layoutBuilder: "Constructor de maquetación",
    megaMenu: "Mega menú",
    metrics: "Métricas",
    modals: "Modales",
    model: "Modelo",
    models: "Modelos",
    client: "Cliente",
    clients: "Clientes",
    modwifi: "Modulo Wifi",
    downldocumentation: "Documentación",
    myproducts: "Mis Productos",
    myprofile: "Mi Perfil",
    newAddress: "Nueva Direccion",
    newCard: "Nueva Tarjeta",
    newTarget: "Nuevo Objetivo",
    pages: "Paginas",
    passwordReset: "Restablecimiento de contraseña",
    privateChat: "Chat privado",
    profile: "Perfil",
    profileOverview: "Descripción general",
    projects: "Proyectos",
    records: "Registros",
    services: "Servicios",
    settings: "Ajustes",
    shareAndEarn: "Compartir Y Ganar",
    signIn: "Entrar",
    signout: "Salir",
    signUp: "Inscribirse",
    softwarev: "Versión del Software",
    status: "Estado",
    statistics: "Estadísticas",
    twoFactorAuth: "Dos Factores",
    upgradePlan: "Plan De Actualización",
    user: "Usuario",
    users: "Usuarios",
    value: "Valor",
    vertical: "Vertical",
    view: "Ver",
    viewUsers: "Ver Usuarios",
    widgets: "Widgets",
    widgetsCharts: "Gráficos",
    widgetsFeeds: "Alimenta",
    widgetsLists: "Liza",
    widgetsMixed: "Mezclada",
    widgetsStatistics: "Estadísticas",
    widgetsTables: "Mesas",
    wizards: "Magos",
    name: "Nombre",
    timestamp: "Marca de Tiempo",
    vendor: "Distribuidor",
  },
  de: {
    dashboard: "Instrumententafel",
    layoutBuilder: "Layout-Builder",
    craft: "Hergestellt",
    pages: "Seiten",
    profile: "Profil",
    profileOverview: "Überblick",
    projects: "Projekte",
    campaigns: "Kampagnen",
    documents: "Unterlagen",
    connections: "Anschlüsse",
    wizards: "Zauberer",
    horizontal: "Horizontal",
    vertical: "Vertikal",
    account: "Konto",
    accountOverview: "Überblick",
    settings: "Die Einstellungen",
    authentication: "Authentifizierung",
    basicFlow: "Grundfluss",
    signIn: "Einloggen",
    signUp: "Anmelden",
    passwordReset: "Passwort zurücksetzen",
    error404: "Fehler 404",
    error500: "Fehler 500",
    apps: "Apps",
    chat: "Plaudern",
    privateChat: "Private Chat",
    groupChat: "Privater Chat",
    drawerChat: "Gruppenchat Schubladen-Chat",
    widgets: "Widgets",
    widgetsLists: "Listen",
    widgetsStatistics: "Statistiken",
    widgetsCharts: "Diagramme",
    widgetsMixed: "Gemischt",
    widgetsTables: "Tabellen",
    widgetsFeeds: "Einspeisungen",
    changelog: "Änderungsprotokoll",
    docsAndComponents: "Dokumente & Komponenten",
    megaMenu: "Mega-Menü",
    exampleLink: "Beispiellink",
    modals: "Modale",
    general: "Allgemeines",
    inviteFriends: "Freunde Einladen",
    viewUsers: "Benutzer Anzeigen.",
    upgradePlan: "Upgrade-Plan",
    shareAndEarn: "Teilen & Verdienen",
    forms: "Formen",
    newTarget: "Neues Ziel",
    newCard: "Neue Karte",
    newAddress: "Neue Adresse",
    createAPIKey: "Api-Key Erstellen",
    twoFactorAuth: "Zwei Faktor Auth.",
    createApp: "App Erstellen",
    createAccount: "Benutzerkonto Erstellen"
  },
  ja: {
    dashboard: "ダッシュボード",
    layoutBuilder: "レイアウトビルダー",
    craft: "作成された",
    pages: "ページ",
    profile: "プロフィール",
    profileOverview: "概要",
    projects: "プロジェクト",
    campaigns: "キャンペーン",
    documents: "書類",
    connections: "接続",
    wizards: "ウィザード",
    horizontal: "横",
    vertical: "垂直",
    account: "アカウント",
    accountOverview: "概要",
    settings: "設定",
    authentication: "認証",
    basicFlow: "基本的な流れ",
    signIn: "サインイン",
    signUp: "サインアップ",
    passwordReset: "パスワードのリセット",
    error404: "エラー404",
    error500: "エラー 500",
    apps: "アプリ",
    chat: "チャット",
    privateChat: "プライベートチャット",
    groupChat: "グループチャット",
    drawerChat: "ドロワーチャット",
    widgets: "ウィジェット",
    widgetsLists: "リスト",
    widgetsStatistics: "統計",
    widgetsCharts: "チャート",
    widgetsMixed: "混合",
    widgetsTables: "テーブル",
    widgetsFeeds: "フィード",
    changelog: "変更ログ",
    docsAndComponents: "ドキュメントとコンポーネント",
    megaMenu: "メガメニュー",
    exampleLink: "リンク例",
    modals: "モーダルズ",
    general: "一般",
    inviteFriends: "友達を招待",
    viewUsers: "ユーザーを表示します",
    upgradePlan: "アップグレードプラン",
    shareAndEarn: "シェア＆稼働",
    forms: "フォーム",
    newTarget: "新しいターゲット",
    newCard: "新しいカード",
    newAddress: "新しいアドレス",
    createAPIKey: "Apiキーを作成します",
    twoFactorAuth: "2つの要因Auth",
    createApp: "アプリを作成します",
    createAccount: "アカウントを作成する"
  },
  fr: {
    dashboard: "Générateur de mise",
    layoutBuilder: "En page",
    craft: "Fabriqué",
    pages: "Pages",
    profile: "Profil",
    profileOverview: "Aperçu",
    projects: "Projets",
    campaigns: "Campagnes",
    documents: "Documents",
    connections: "Connexions",
    wizards: "Sorciers",
    horizontal: "Horizontal",
    vertical: "Verticale",
    account: "Compte",
    accountOverview: "Aperçu",
    settings: "Paramètres",
    authentication: "Authentification",
    basicFlow: "Flux de base",
    signIn: "SS'identifier",
    signUp: "Inscrivez-vous",
    passwordReset: "Réinitialisation du mot de passe",
    error404: "Erreur 404",
    error500: "Erreur 500",
    apps: "Applications",
    chat: "Discuter",
    privateChat: "Discussion privée",
    groupChat: "Discussion de groupe",
    drawerChat: "Chat de tiroir",
    widgets: "Widgets",
    widgetsLists: "Listes",
    widgetsStatistics: "Statistiques",
    widgetsCharts: "Graphiques",
    widgetsMixed: "Mixte",
    widgetsTables: "Les tables",
    widgetsFeeds: "Flux",
    changelog: "Journal des modifications",
    docsAndComponents: "Documents & composants",
    megaMenu: "Méga Menu",
    exampleLink: "Exemple de lien",
    modals: "Modals",
    general: "Général",
    inviteFriends: "Inviter Des Amis",
    viewUsers: "Voir Les Utilisateurs",
    upgradePlan: "Plan De Mise À Niveau",
    shareAndEarn: "Partager Et Gagner",
    forms: "Formes",
    newTarget: "Nouvelle Cible",
    newCard: "Nouvelle Carte",
    newAddress: "Nouvelle Adresse",
    createAPIKey: "Créer Une Touche Api",
    twoFactorAuth: "Deux Facteurs D'Authentification",
    createApp: "Créer Une Application",
    createAccount: "Créer Un Compte"
  }
};

const i18n = createI18n({
  legacy: false,
  locale: "en",
  globalInjection: true,
  messages
});

export default i18n;
