
import { defineComponent, ref } from "vue";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "maintenance-table-devices",
  components: {},
  props: {
    widgetClasses: String,
    BtnVisible: Boolean
  },
  data() {
    return {
      list: [{}]
    }
  },
  mounted() {
    const lista: any[] = [];
    const fetchDevices = async () => {
      await ApiService.setHeader();
      const datos = await ApiService.get("/devices/maintenance");
      const keys = Object.keys(datos.data);
      this.list = datos.data.devices;
    }
    fetchDevices()
  },
});
