import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import firebase from "firebase";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue")
      },
      {
        path: "/device",
        name: "device",

        component: () => import("@/views/device/Device.vue"),
        props: true,
        children: [
          {
            path: "records",
            name: "device-records",
            component: () => import("@/views/device/Records.vue"),
            props: true
          },
          {
            path: "statistics",
            name: "device-statistics",
            component: () => import("@/views/device/Statistics.vue"),
            props: true
          }
        ]
      },
      {
        path: "/account",
        name: "account",
        component: () => import("@/views/account/Account.vue"),
        children: [
          {
            path: "devices",
            name: "account-devices",
            component: () => import("@/views/account/Devices.vue")
          },
          {
            path: "settings",
            name: "account-settings",
            component: () => import("@/views/account/Settings.vue")
          },
          {
            path: "models",
            name: "account-models",
            component: () => import("@/views/account/Models.vue")
          },
          {
            path: "model",
            name: "model",
            component: () => import("@/views/account/Model.vue"),
            props: true
          },
          {
            path: "users",
            name: "account-users",
            component: () => import("@/views/account/Users.vue")
          },
          {
            path: "clients",
            name: "account-clients",
            component: () => import("@/views/account/Clients.vue")
          }
        ]
      },
      {
        path: "/services",
        name: "services",
        component: () => import("@/views/Services.vue")
      },
      {
        path: "/admin",
        name: "admin",
        component: () => import("@/views/Admin.vue"),
        meta: {
          requiresAuth: true,
          hidden: false // La ruta se ocultará si showAdminRoute es falso
        }
      },
      {
        path: "/tutorials",
        name: "tutorials",
        component: () => import("@/views/Tutorials.vue")
      }
    ]
  },
  {
    path: "/sign-in",
    name: "sign-in",
    component: () => import("@/views/auth/SignIn.vue")
  },
  {
    path: "/sign-up",
    name: "sign-up",
    component: () => import("@/views/auth/SignUp.vue")
  },
  {
    path: "/password-reset",
    name: "password-reset",
    component: () => import("@/views/auth/PasswordReset.vue")
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/error/Error404.vue")
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/error/Error500.vue")
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404"
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  // reset config to initial state
  //console.log(to, from)
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth);
  if (requiresAuth) {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        console.log("Usuario logado");
        next();
      } else {
        console.log("Usuario No Autenticado");
        next("/sign-in");
      }
    });
  } else {
    next();
  }

  store.commit(Mutations.RESET_LAYOUT_CONFIG);
  store.dispatch(Actions.VERIFY_AUTH);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
