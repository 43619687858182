import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";

interface Device {
  model: string;
  mac: string;
  type: string;
  softver: string;
  wifi: string;
  docu: string;
  records: {};
}

interface StoreInfo {
  device: Device;
}

@Module
export default class DeviceModule extends VuexModule implements StoreInfo {
  device = {} as Device;
  values: number[] = [];
  labels: string[] = [];

  /**
   * Get breadcrumb object for current page
   * @returns object
   */
  get getDevice(): Device {
    return this.device;
  }
  get getStatistics(): object {
    return this.values;
  }
  get getLabels(): object {
    return this.labels;
  }
  /**
   * Get breadcrumb array for current page
   * @returns object
   */

  @Mutation
  [Mutations.SET_DEVICE_MUTATION](payload) {
    this.device = payload;
  }
  @Mutation
  [Mutations.SET_STATISTICS_MUTATION](payload) {
    this.values = payload.valores;
    this.labels = payload.etiquetas
  }
  @Mutation
  [Mutations.SET_LABELS_MUTATION](playload) {
    this.labels = playload;
  }

  @Action
  [Actions.SET_DEVICE_ACTION](payload) {
    this.context.commit(Mutations.SET_DEVICE_MUTATION, payload);
  }
  @Action
  async [Actions.SET_STATISTICS](payload) {
    console.log(payload)
    const  params = {
      deviceId: payload.deviceId,
      register: payload.register,
      period: payload.period,
      backTime: payload.backTime 
    };
    await ApiService.setHeader();
    const datos = await ApiService.getObj("metrics", {params});
    const d = JSON.parse(localStorage.device);
    let elem = {
      selector: "",
      info: {
          description: "",
          metric: "",
          operation: {
              type: "",
              value: 0
          }
      }
  };
    for (const elemento of d.model.util["interact"]) {
        if (elemento["register"].toString() === localStorage.staticsREG) {
            elem = elemento.components[0].type;
        }
    }
    for (const elemento of d.model.util["visualize"]) {
        if (elemento["register"].toString() === localStorage.staticsREG) {
            elem =  elemento.components[0].type;
        }
    }
    const valores: number[] = [];
    const etiquetas: string[] = [];
    console.log("Númnero de registros: ",datos.data.values.length)
    let valor = 0
    datos.data.values.map(item => {
      const localDate = new Date(item.timestamp);
      etiquetas.push(localDate.toLocaleTimeString());
      if (item.value == "") valores.push(valor);
      else {
        valor = parseInt(item.value);
        console.log(elem)
        if (elem.info.operation !== undefined){
          if (elem.info.operation.type == "divide")
            valor = Math.round((valor + Number.EPSILON) * 10 )/ (10 * elem.info.operation.value)
        }
        //valor = Math.round((valor + Number.EPSILON) * 10) / 100;
        valores.push(valor);
      }
    });
    const pay = {
      valores,
      etiquetas
    }
    this.context.commit(Mutations.SET_STATISTICS_MUTATION, pay)
    //this.context.commit(Mutations.SET_LABELS_MUTATION, etiquetas)
  }
}
