
import { defineComponent, ref, computed } from "vue";
import Dropdown3 from "@/components/dropdown/Dropdown3.vue";
import { useI18n } from "vue-i18n";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";

export default defineComponent({
  name: "kt-account",
  components: {
    //Dropdown3
  },
  data() {
    return {
      totalUsers: '',
    }
  },
  setup() {
    const { t, te } = useI18n();
    const store = useStore();
    const totalDevices = ref(String);
    const totalUser = ref(String) || 0;
    const user = computed(() => { return store.getters.currentUser })
    const translate = text => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };
    const fetchData = async () => {
      await ApiService.setHeader();
      const resultTotalDevices = await ApiService.get("devices/totals");
      const resultTotalUser = await ApiService.get("users/totals");
      totalDevices.value = resultTotalDevices.data.total;
      totalUser.value = resultTotalUser.data.total || 0;
    };
    return {
      translate,
      fetchData,
      totalDevices,
      totalUser,
      user,
    };
  },
  created() {
    this.fetchData();
  }
});
