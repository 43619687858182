
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "services-table",
  components: {
    
  },
  props: {
    widgetClasses: String,
    list: []
  },
  setup() {
    const { t, te } = useI18n();
    const translate = text => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };
    return {
      translate
    };
  },
  methods: {
  },
  beforeCreate() {
    console.log(this.list);
  }

});
