import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DeviceRecords = _resolveComponent("DeviceRecords")

  return (_openBlock(), _createBlock(_component_DeviceRecords, {
    records: _ctx.records,
    id: _ctx.id,
    mac: _ctx.mac,
    regmetrics: _ctx.regmetrics,
    regdescript: _ctx.regdescript,
    "widget-classes": "mb-5 mb-xl-8"
  }, null, 8, ["records", "id", "mac", "regmetrics", "regdescript"]))
}