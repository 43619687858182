
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ApiService from "@/core/services/ApiService";

interface APIData {
  register: string;
  value: string;
}

export default defineComponent({
  name: "create-api-key-modal",
  props: {
    device: String
  },
  components: {
    ErrorMessage,
    Field,
    Form
  },
  setup(props: any) {
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const modalRef = ref<null | HTMLElement>(null);

    const apiData = ref<APIData>({
      register: "",
      value: ""
    });

    const validationSchema = Yup.object().shape({
      recordNumber: Yup.string()
        .required()
        .label("Nº Registro"),
      recordValue: Yup.string()
        .required()
        .label("Valor")
    });

    const submit = async () => {
      if (!submitButtonRef.value) {
        return;
      }
      await ApiService.setHeader();
      const datos = await ApiService.post("devices/" + props.device + "/registers", apiData.value)


      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");

      setTimeout(() => {
        if (submitButtonRef.value) {
          submitButtonRef.value.disabled = false;

          submitButtonRef.value?.removeAttribute("data-kt-indicator");
        }

        Swal.fire({
          text: "Form has been successfully submitted!",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary"
          }
        }).then(() => {
          //window.location.reload();
        });
      }, 2000);
    };

    return {
      apiData,
      validationSchema,
      submit,
      submitButtonRef,
      modalRef
    };
  }
});
