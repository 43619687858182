
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import KTModalCard from "@/views/modals/Card.vue";
import KTCreateAPIKeyModal from "@/components/modals/forms/ChangeValuesRecords.vue";

export default defineComponent({
  name: "device-records",
  components: {
    KTModalCard,
    KTCreateAPIKeyModal
  },
  props: {
    widgetClasses: String,
    id: String,
    mac: String,
    model: String,
    regdescript: [],
    regmetrics: [],
    records: []
  },
  setup() {
    const { t, te } = useI18n();
    const translate = text => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };
    return {
      translate
    };
  }
});
