
import { defineComponent, onMounted } from "vue";
import MaintenanceTableDevices from "@/components/widgets/sistena/MaintenanceTableDevices.vue";
import MixedCardDevices from "@/components/widgets/sistena/MixedCardDevices.vue";
import BannerButton from "@/components/widgets/sistena/BannerButton.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "dashboard",
  components: {
    MaintenanceTableDevices,
    MixedCardDevices,
    BannerButton
  },
  setup() {
    const list = [
      {
        title: "Mis Productos",
        target: "#/account/devices",
        description: "Descarga de documentaciones e información de los mismos",
        color: "danger",
        icon: "media/icons/duotone/Shopping/Cart3.svg"
      },
      {
        title: "Tutorial",
        target: "#/tutorials",
        description: "¿Cómo utilizar la herrramienta?",
        color: "primary",
        icon: "media/icons/duotone/Home/Building.svg"
      },
      {
        title: "Mis estadísticas",
        target: "#/account/devices",
        description: "Descarga de estadísticas de mis equipos",
        color: "success",
        icon: "media/icons/duotone/Shopping/Chart-bar1.svg"
      }
    ];
    onMounted(() => {
      setCurrentPageTitle("Dashboard");
    });
    return {
      list
    };
  }
});
